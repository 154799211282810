import React from 'react';
import Page from '../components/Page';
import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

export default function About({ data }) {
  return (
    <>
      <Page title={'Om oss'}>
      <main className="isolate">
      <div className="mx-auto max-w-7xl px-6 py-20  lg:px-8">
        <div className="mx-auto max-w-2xl lg:mx-0 lg:grid lg:max-w-none lg:grid-cols-2 lg:gap-x-16 lg:gap-y-6 xl:grid-cols-1 xl:grid-rows-1 xl:gap-x-8">
          <h1 className="max-w-2xl text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl lg:col-span-2 xl:col-auto">Upptäck och utforska vildmarken med oss på Naturlabbet.</h1>
          <div className="mt-6 max-w-xl lg:mt-0 xl:col-end-1 xl:row-start-1">
            <p className="text-lg leading-8 text-gray-600">Hos oss hittar du guider relaterat till vandring och friluftslivet i Sverige, tips för utrustning och klädsel, och mycket annat. Naturlabbet är för alla som vill upptäcka och njuta av vår fantastiska svenska natur!</p>
          </div>
          <GatsbyImage image={data.homeTop.childImageSharp.gatsbyImageData}  alt="" className="mt-10 aspect-[6/5] w-full max-w-lg rounded-2xl object-cover sm:mt-16 lg:mt-0 lg:max-w-none xl:row-span-2 xl:row-end-2 xl:mt-36"/>
        </div>
      </div>


    <div className="mt-32 overflow-hidden sm:mt-40 mb-32">
      <div className="mx-auto max-w-7xl px-6 lg:flex lg:px-8">
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-12 gap-y-16 lg:mx-0 lg:min-w-full lg:max-w-none lg:flex-none lg:gap-y-8">
          <div className="lg:col-end-1 lg:w-full lg:max-w-lg lg:pb-8">
            <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Vilka är vi?</h2>
            <p className="mt-6 text-xl leading-8 text-gray-600">Naturlabbet drivs av ett gäng friluftsfantaster som älskar att dela med oss av våra erfarenheter, tips och tricks.</p>
            <p className="mt-6 text-base leading-7 text-gray-600"> Vi hoppas att med denna hemsida kunna bidra med inspiration till att fler ger sig ut och upplever naturen!</p>
            <p className="mt-6 text-base leading-7 text-gray-600">Friluftsliv, natur, vandring - Naturlabbet förvandlar dina äventyr med unika guider, tips och inspiration. Välkommen!</p>
          </div>
          <div className="flex flex-wrap items-start justify-end gap-6 sm:gap-8 lg:contents">
            <div className="w-0 flex-auto lg:ml-auto lg:w-auto lg:flex-none lg:self-end">
              <GatsbyImage image={data.hund.childImageSharp.gatsbyImageData} alt="" className="aspect-[7/5] w-[37rem] max-w-none rounded-2xl bg-gray-50 object-cover"/>
            </div>
            <div className="contents lg:col-span-2 lg:col-end-2 lg:ml-auto lg:flex lg:w-[37rem] lg:items-start lg:justify-end lg:gap-x-8">
              <div className="order-first flex w-64 flex-none justify-end self-end lg:w-auto">
                <GatsbyImage image={data.runner.childImageSharp.gatsbyImageData} alt="" className="aspect-[4/3] w-[24rem] max-w-none flex-none rounded-2xl bg-gray-50 object-cover"/>
              </div>
              <div className="flex w-96 flex-auto justify-end lg:w-auto lg:flex-none">
                <GatsbyImage image={data.kikare.childImageSharp.gatsbyImageData} alt="" className="aspect-[7/5] w-[37rem] max-w-none flex-none rounded-2xl bg-gray-50 object-cover"/>
              </div>
              <div className="hidden sm:block sm:w-0 sm:flex-auto lg:w-auto lg:flex-none">
                <GatsbyImage image={data.nikkalouta.childImageSharp.gatsbyImageData} alt="" className="aspect-[4/3] w-[24rem] max-w-none rounded-2xl bg-gray-50 object-cover"/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </main>
       
      </Page>
    </>
  );
}

export const pageQuery = graphql`
  query {
    homeTop: file(relativePath: { eq: "about-us.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    hund: file(relativePath: { eq: "talta-hund.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    nikkalouta: file(relativePath: { eq: "nikkalouta.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    kikare: file(relativePath: { eq: "natur-kikare.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    runner: file(relativePath: { eq: "trail-runner.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
  }
`;